<template>
    <v-container fluid>
        <v-row>
            <v-col cols="12">
                <div class="d-flex">
                    <v-breadcrumbs :items="breadcumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.href"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                                >
                                {{ item.text }}
                            </v-breadcrumbs-item>
                        </template>
                    </v-breadcrumbs>
                    <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                        <v-layout align-center pr-4>
                        <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                        <v-layout column>
                            <div>
                            <strong>{{ snackbar.title }}</strong>
                            </div>
                            <div>{{ snackbar.text }}</div>
                        </v-layout>
                        </v-layout>
                        <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                        <v-icon>clear</v-icon>
                        </v-btn>
                    </v-snackbar>
                    <v-spacer></v-spacer>
                </div>
            </v-col>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid class="p-3">
                        <v-row class="mb-1">
                            <v-col cols="12">
                                <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-filter</v-icon> Filter: </h6>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_from"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_from"
                                        type="month"
                                        no-title
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2" >
                                <v-menu
                                    ref="modal_to"
                                    v-model="modal_to"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="date_to"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-0 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="date_to"
                                        type="month"
                                        no-title
                                        @input="modal_to = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-slot-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="machineType"
                                    :items="machineTypes"
                                    item-value="mach_type"
                                    item-text="descr"
                                    label="Machine Type"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => fetchMachineID(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-state-machine"
                                    clearable
                                    solo
                                    dense
                                    v-model="machId"
                                    :items="machIds"
                                    item-value="mach_id"
                                    item-text="descr"
                                    label="Machine Id"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-compost"
                                    clearable
                                    solo
                                    dense
                                    v-model="commodity"
                                    :items="commodities"
                                    item-value="commodity_id"
                                    item-text="descr"
                                    label="Commodity Id"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [fetchProdThick(event), fetchProdWidth(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="descr"
                                    item-text="descr"
                                    label="Brand"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                    @change="(event) => [fetchProdThick(event), fetchProdWidth(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    dense
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    item-text="thick"
                                    label="Thickness"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    dense
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    dense
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    :item-text="item => item.quality_id + ' - ' + item.descr"
                                    label="Quality"
                                    class="ma-0 pa-0 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row align="center" justify="center" class="mt-5">
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block  style="padding: 21px;"
                                elevation="2" small @click="clear()">Clear</v-btn>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-btn class="border-12" block style="padding: 21px;"
                                color="info" elevation="2" small @click="fetchData()"><v-icon>mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="mt-5">
            <v-col class="col-12" sm="12" md="6">
                <v-card id="sumScShipmentAnalysis" outlined height="450">
                    <v-card-text>
                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon> Chart Analysis Pickling: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container1" style="height: 400px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col class="col-12" sm="12" md="6">
                <v-card outlined height="450">
                    <v-card-text>
                        <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-poll</v-icon> Chart Analysis Rolling: </h6>
                        <v-img
                        class="text-center mt-5 mx-auto"
                        lazy-src="@/assets/empty_data.png"
                        max-width="300"
                        src="@/assets/empty_data.png"
                        v-if="showData"
                        ></v-img>
                        <div id="container2" style="height: 400px; width: 100%;"></div>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
            v-model="dialog"
            persistent
            max-width="1200px"
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card class="rounded-l">
                <v-card-title class="text-center mb-0 pb-0">
                    <v-row>
                        <v-col cols="12">
                            <div class="d-flex p-2">
                                <div class="ml-1">
                                    <h6 class="indigo-text m-0 primary--text"><v-icon color="primary" class="mr-3">mdi-details</v-icon> {{ detail ? detail.period : '' }}  # {{ detail ? detail.thick_name : '' }} </h6>
                                     
                                </div>
                                <v-spacer></v-spacer>
                                <v-btn
                                    class="mx-2"
                                    fab
                                    x-small
                                    color="#e83e8c;"
                                    @click="[dialog = false]"
                                >
                                    <v-icon>
                                        mdi-close
                                    </v-icon>
                                </v-btn>
                            </div>
                        </v-col>
                        <v-divider></v-divider>
                    </v-row>
                </v-card-title>
                <v-card-text class="ma-0">
                    <v-data-table
                        dense
                        :headers="header"
                        :items="detailData"
                        class="elevation-1 mt-3"
                        :items-per-page="30"
                        :footer-props="{
                            'items-per-page-options': [10, 20, 30, 40, 50, -1]
                        }"
                        :loading="$store.state.overlay"
                        height="400"
                        fixed-header
                        :divider="true"
                        :light="true"
                        :search="searchItem" 
                        :item-class="tr_datatable"
                    >    
                        <template v-slot:top>
                            <v-toolbar flat color="white" class="mt-2 mb-2">
                                <div class="d-flex w-100">
                                    <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="searchItem" append-icon="mdi-magnify" label="Search" dense single-line hide-details></v-text-field>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                    small
                                    color="#005c37"
                                    class="py-5 mr-3 text-white"
                                    >
                                        <v-icon>mdi-file-excel</v-icon>
                                        <download-excel
                                            class="text"
                                            :fetch           = "exportExcelItem"
                                            :fields="headersColumn"
                                            :before-generate = "startDownload"
                                            :before-finish   = "finishDownload">
                                            Export Excel
                                        </download-excel>
                                    </v-btn>
                                </div>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.width`]="{ item }">
                            {{ $store.getters.convertToCurrencyNoFrac(item.width) }}
                        </template> 
                        <template v-slot:[`item.wgt`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(parseFloat(item.wgt) / 1000) }}
                        </template> 
                        <template v-slot:[`item.thick`]="{ item }">
                            {{ $store.getters.convertToCurrencyUs(item.thick) }}
                        </template> 
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data(){
        return {
            loading: false,
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Mill',
                disabled: false,
                href: '/admin/sm',
                },
                {
                text: 'Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Thickness Composition',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machineType: '',
            machineTypes: [],
            machId: '',
            machIds: [],
            commodity: '',
            commodities: [],
            brand: '',
            brands: [],
            thick: '',
            thickness: [],
            width: '',
            widths: [],
            quality: '',
            qualities: [],
            date_from: '',
            modal: false,
            modal_to: false,
            date_to: '',
            showData: true,
            x: window.matchMedia("(max-width: 991px)"),
            searchItem: '',
            header:[
                { text: 'Machine Type', value: 'machine_type' , align:'start'},
                { text: 'Machine ID', value: 'machine_id' , align:'left'},
                { text: 'Commodity', value: 'commodity_descr' , align:'left'},
                { text: 'Brand', value: 'brand' , align:'left'},
                { text: 'Quality', value: 'quality_id' , align:'left', width:50},
                { text: 'Thick', value: 'thick' , align:'right'},
                { text: 'Width', value: 'width' , align:'right'},
                { text: 'Weight (TON)', value: 'wgt' , align:'right'},
            ],
            headersColumn:{
                'Period': 'period', 
                'Thick Name': 'thick_name',
                'Machine Type' : 'machine_type',
                'Machine ID' : 'machine_id',
                'Commodity' : 'commodity_descr',
                'Brand' : 'brand',
                'Quality' : 'quality_id',
                'Thick': 'thick',
                'Width': 'width',
                'Weight (KG)' : 'wgt'
            },
            detailData:[],
            detail: null,
            dialog: false
        }  
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.fetchMachineType()
        await this.fetchCommodity()
        await this.fetchBrand()
        await this.fetchProdQuality()
        this.$store.dispatch('setOverlay', false)
    },
    methods:{
        tr_datatable(item) {
            var rowClass = 'tr_datatable'
            return rowClass;
        },
        clear(){
            this.date_from = ''
            this.date_to = ''
            this.machineType = ''
            this.machId = ''
            this.commodity = ''
            this.brand = ''
            this.thick = ''
            this.width = ''
            this.quality = ''
        },
        async fetchMachineType(){
            var reqBody = {
                'mach_type': this.machineType ? this.machineType : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/machine_type', reqBody, false, false, false)
            if (respData.status === 200) {
                this.machineTypes = respData.data
            }
        },
        async fetchMachineID(event){
            if (event == '' || event == null) {
                this.machId = ''
            } else {
                var reqBody = {
                    'mach_type': this.machineType ? this.machineType : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/machine_id', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.machIds = respData.data
                }
            }
        },
        async fetchCommodity(){
            var reqBody = {
                'commodity_id': this.commodity ? this.commodity : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/commodity', reqBody, false, false, false)
            if (respData.status === 200) {
                this.commodities = respData.data
            }
        },
        async fetchBrand(){
            var reqBody = {
                'brand_id': this.brand ? this.brand : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/prod_brand', reqBody, false, false, false)
            if (respData.status === 200) {
                this.brands = respData.data
            }
        },
        async fetchProdThick(event){
            if (event == '' || event == null) {
                this.thick = ''
            } else {
                var reqBody = {
                    'brand_id': this.brand ? this.brand : '',
                    'commodity_id': this.commodity ? this.commodity : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/prod_thick', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.thickness = respData.data
                }
            }
        },
        async fetchProdWidth(event){
            if (event == '' || event == null) {
                this.width = ''
            } else {
                var reqBody = {
                    'brand_id': this.brand ? this.brand : '',
                    'commodity_id': this.commodity ? this.commodity : ''
                }
                const respData = await backendApi.fetchCore('/v2/master/sm/prod_width', reqBody, false, false, false)
                if (respData.status === 200) {
                    this.widths = respData.data
                }
            }
        },
        async fetchProdQuality(){
            var reqBody = {
                'quality_id': this.quality ? this.quality : ''
            }
            const respData = await backendApi.fetchCore('/v2/master/sm/prod_quality', reqBody, false, false, false)
            if (respData.status === 200) {
                this.qualities = respData.data
            }
        },
        async fetchData(){
            this.$store.dispatch('setOverlay', true)

            if (this.date_from == '') {
                this.$store.dispatch('setOverlay', false)

                this.snackbar = {
                    color: "warning",
                    icon: "mdi-alert-circle",
                    mode: "multi-line",
                    position: "top",
                    timeout: 7500,
                    title: "Warning",
                    text: "Please fill start Period",
                    visible: true
                };
                return false
            }

            var start = this.date_from ? new Date(this.date_from).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.date_to ? new Date(this.date_to).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'start': start,
                'end': end,
                'mach_type': this.machineType ? this.machineType : '',
                'mach_id': this.machId ? this.machId : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'brand': this.brand ? this.brand : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/thickness-composition', reqBody, false, false, false)
            if (respData.status === 200) {
                var container = "container1";
                var container2 = "container2";

                var inputJson = respData.data
                const transformedData = {};

                inputJson.forEach(item => {
                    const machType = item.mach_type.trim() == "" ? 'empty' : item.mach_type.toLowerCase();
                    if (!transformedData[machType]) {
                        transformedData[machType] = [];
                    }
                    transformedData[machType].push({
                        x: new Date(item.period.substr(0, 4) + '-' + item.period.substr(5, 7)),
                        y: parseFloat(item.total_wgt),
                        mach_type: item.mach_type,
                        thick_name: item.thick_name
                    });
                });

                var pickling = (transformedData.pk) ? transformedData.pk : []
                var rolling = (transformedData.rl) ? transformedData.rl : []

                // console.log(pickling);
                // console.log(rolling);

                var inputJsonPickling = pickling
                const transformedDataPickling = {};

                inputJsonPickling.forEach(item => {
                    const thickName = item.thick_name.trim() == "" ? 'empty' : item.thick_name.toLowerCase();
                    if (!transformedDataPickling[thickName]) {
                        transformedDataPickling[thickName] = [];
                    }
                    transformedDataPickling[thickName].push({
                        x: item.x,
                        y: parseFloat(item.y),
                        thick_name: item.thick_name,
                        mach_type: item.mach_type
                    });
                });

                var empty = (transformedDataPickling.empty) ? transformedDataPickling.empty : []
                var heavy = (transformedDataPickling.heavy) ? transformedDataPickling.heavy : []
                var medium = (transformedDataPickling.medium) ? transformedDataPickling.medium : []
                var light = (transformedDataPickling.light) ? transformedDataPickling.light : []

                var inputJsonRolling = rolling
                const transformedDataRolling = {};

                inputJsonRolling.forEach(item => {
                    const thickName = item.thick_name.trim() == "" ? 'empty' : item.thick_name.toLowerCase();
                    if (!transformedDataRolling[thickName]) {
                        transformedDataRolling[thickName] = [];
                    }
                    transformedDataRolling[thickName].push({
                        x: item.x,
                        y: parseFloat(item.y),
                        thick_name: item.thick_name,
                        mach_type: item.mach_type
                    });
                });
                
                var emptyRolling = (transformedDataRolling.empty) ? transformedDataRolling.empty : []
                var heavyRolling = (transformedDataRolling.heavy) ? transformedDataRolling.heavy : []
                var mediumRolling = (transformedDataRolling.medium) ? transformedDataRolling.medium : []
                var lightRolling = (transformedDataRolling.light) ? transformedDataRolling.light : []

                await this.getChart(empty, light, medium, heavy, "Not Definition", "Light", "Medium", "Heavy", container, 'column');
                await this.getChart(emptyRolling, lightRolling, mediumRolling, heavyRolling, "Not Definition", "Light", "Medium", "Heavy", container2, 'column');

                $('html, body').animate({
                    scrollTop: $("#sumScShipmentAnalysis").offset().top
                }, 1200)

                this.showData = false

                this.$store.dispatch('setOverlay', false)
            }
        },
        getChart(dt1, dt2, dt3, dt4, name1, name2, name3, name4, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: 'Thickness Composition',
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: 'Weight',
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    // labelFormatter: function (e) {
                    //     console.log(new Date(e.value - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 7));
                    //     // var month = e.value.substr(0, 4) + '-' + e.value.substr(5, 7)
                    //     // console.log(month);
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
                    // },
                    interval: 1,
                    intervalType: "month"
                },
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name1,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "inside",  
                        indexLabelOrientation: "vertical",
                        color: "#BBDEFB",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name2,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "outside",  
                        indexLabelOrientation: "vertical",
                        color: "#64B5F6",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name3,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "outside",  
                        indexLabelOrientation: "vertical",
                        color: "#1E88E5",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name4,
                        indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        indexLabelPlacement: "outside",  
                        indexLabelOrientation: "vertical",
                        color: "#0D47A1",
                        // yValueFormatString: "#,###,,,.##",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;

            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async showDetail(e){
            console.log(e.dataPoint);
            this.detail = {
                period: (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,""),
                thick_name: e.dataPoint.thick_name,
                mach_type: e.dataPoint.mach_type,
            }
            this.detailData = []
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'period': (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,""),
                'thick_name': e.dataPoint.thick_name,
                'mach_type': e.dataPoint.mach_type,
                'mach_id': this.machId ? this.machId : '',
                'commodity_id': this.commodity ? this.commodity : '',
                'brand': this.brand ? this.brand : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'quality_id': this.quality ? this.quality : ''
            }

            const respData = await backendApi.fetchCore('/v2/sm/thickness-composition/show', reqBody, false, false, false)
            if (respData.status === 200) {
                this.detailData = respData.data
                this.dialog = true
            }

            this.$store.dispatch('setOverlay', false)

        },
        async exportExcelItem(){
            var reqBody = {
                'period': this.detail ? this.detail.period : '',
                'thick_name': this.detail ? this.detail.thick_name : '',
                'mach_type': this.detail ? this.detail.mach_type : '',
            }

            const respData = await backendApi.fetchCore('/v2/sm/thickness-composition/show', reqBody, false, false, false)
            if (respData.status === 200) {
                return respData.data
            }
        },
        startDownload(){
            this.$store.dispatch('setOverlay', true)
        },
        finishDownload(){
            this.$store.dispatch('setOverlay', false)
        },
    },    
}
</script>

<style>

</style>